body {
  margin: 0;
  padding: 0;
  font-family: PFFuturaNeuBook, "Open Sans",Roboto,"Helvetica Neue",Arial,sans-serif;
}

a:visited {
  color: currentColor
}

.canvasjs-chart-toolbar {
  display: none !important;
  z-index: -200;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bodyColor);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F47C06;
}

@font-face {
  font-family: 'FuturaStd-Light';
  src: url('./assets/fonts/FuturaStd-Light.eot');
  src: local('FuturaStd-Light'),
  url('./assets/fonts/FuturaStd-Light.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/FuturaStd-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'FuturaStd-Heavy';
  src: url('./assets/fonts/FuturaStd-Heavy.eot');
  src: local('FuturaStd-Heavy'),
  url('./assets/fonts/FuturaStd-Heavy.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/FuturaStd-Heavy.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'FuturaStd-Medium';
  src: url('./assets/fonts/FuturaStd-Medium.eot');
  src: local('FuturaStd-Medium'),
  url('./assets/fonts/FuturaStd-Medium.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/FuturaStd-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: PFFuturaNeuBook;
  src: url('./assets/fonts/PFFuturaNeuBook.eot');
  src: local('PFFuturaNeuBook'),
  url('./assets/fonts/PFFuturaNeuBook.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/PFFuturaNeuBook.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: PFFuturaNeuBookRegular;
  src: url('./assets/fonts/PFFuturaNeuBookRegular.eot');
  src: local('PFFuturaNeuBookRegular'),
  url('./assets/fonts/PFFuturaNeuBookRegular.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/PFFuturaNeuBookRegular.ttf') format('truetype'); /* Safari, Android, iOS */
}
